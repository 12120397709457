import { Component, StrictMode } from "react";
import HomeScreen from "./screens/home";
import AgencyScreen from "./screens/agency";
import AcademyScreen from "./screens/academy";
import EventsScreen from "./screens/events";
import EventScreen from "./screens/event";
import CareersScreen from "./screens/careers";
import axios from "axios";
import ModelScreen from "./screens/model";
import { events } from "./statics/content";
import NewstScreen from "./screens/news";

export default class App extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      page: "",
      events: { loading: true, data: [] },
    };
  }

  _setPage = (v: string) => {
    this.setState({ page: "home" });
    window.history.pushState("UC", "UC", "/" + v);
    setTimeout(() => this.setState({ page: v.split("/")[0] }), 100);
  };

  async componentDidMount() {
    const { loading }: any = this.state;
    const page = window.location.pathname.split("/")[1];
    this.setState({ page });

    if (!loading) {
      this.setState({ loading: true });
      // await axios
      //   .get("https://api.avidhilda.com/api/event/getevent")
      //   .then((res) => {
      //     this.setState({ events: { loading: false, data: res.data.data } });
      //   })
      //   .catch(() => {});
      this.setState({ events: { loading: false, data: events } });
    }

    window.onpopstate = () => {
      this.setState({ page: "home" });
      const page = window.location.pathname.split("/")[1];
      setTimeout(() => this.setState({ page }), 100);
    };
  }

  render() {
    const { page, events }: any = this.state;
    // event, events, academy, agency
    return (
      <StrictMode>
        {(page === "events" ||
          page === "agency" ||
          page === "academy" ||
          page === "") && (
          <StrictMode>
            <HomeScreen setPage={this._setPage} events={events} />
            {page === "events" && (
              <EventsScreen setPage={this._setPage} events={events} />
            )}
            {page === "agency" && (
              <AgencyScreen setPage={this._setPage} events={events} />
            )}
            {page === "academy" && (
              <AcademyScreen setPage={this._setPage} events={events} />
            )}
          </StrictMode>
        )}
        {page === "event" && (
          <EventScreen setPage={this._setPage} events={events} />
        )}
        {page === "careers" && (
          <CareersScreen setPage={this._setPage} events={events} />
        )}
        {page === "model" && (
          <ModelScreen setPage={this._setPage} events={events} />
        )}
        {page === "news" && (
          <NewstScreen setPage={this._setPage} events={events} />
        )}
      </StrictMode>
    );
  }
}

/*
npm run build | tar.exe -a -c -f build.zip build/* | explorer . | git add . | git commit -m "Ordinery Push" | git push

npm run build && tar -czf build.tar.gz build/* && open . && git add . && git commit -m "Ordinary Push" && git push
*/
