import { Component } from "react";
import ScreenHeader, { ScreenFooter } from "../components/foot&head";
import AboutContainer from "../components/about";
import axios from "axios";
import EventsList from "../components/eventsList";
import RegisterForm from "../components/registerForm";
import cs from "../styles/agency.module.css";

const bannerItems = [
  {
    title: "OUR MODELS",
    desc: "All of our models are highly experienced in posing for pictures and drawings by your guests, who have sharp eyes and are willing to study the human form. They will definitely bring style and a sense of passion to your shows.",
  },
  {
    title: "UC AGENCY",
    desc: "Unlock Your Full Potential with Universal Couture – We Believe in Your Unique Beauty",
  },
];

const modelsCat = [
  { beTl: "Editorial", title: "Editorial", value: "editorialModel" },
  { beTl: "Diverse", title: "Diverse", value: "diverseModel" },
  // { beTl: "Fresh Faces", title: "Fresh Faces", value: "freshFaces" },
  { beTl: "Plus Size", title: "Plus Size", value: "plusSize" },
];

export default class AgencyScreen extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSuccesPop: false,
      models: [],
      modelTab: modelsCat[0],
      isMaleCat: false,
      page: "", //register
      bannerItem: bannerItems[0],
      reset: true,
    };
  }

  async componentDidMount() {
    var itemCount = 0;
    setInterval(() => {
      if (itemCount === 1) itemCount = 0;
      else itemCount++;
      this.setState({ bannerItem: bannerItems[itemCount] });
    }, 8000);

    const page = window.location.pathname.split("/")[2];
    this.setState({ page, modelsLoading: true, modelsError: false });
    await axios
      .get("https://api.avidhilda.com/api/models")
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          var element = res.data.data[i].category;
          if (element === "Fresh Faces") element = "Editorial";
        }
        this.setState({ models: res.data.data.reverse() });
      })
      .catch(() => this.setState({ modelsError: true }));
    this.setState({ modelsLoading: false });
  }

  _checkCat = (it: any) => {
    const { modelTab, isMaleCat }: any = this.state;
    if (it.category === modelTab.beTl) return false;
    if (it.gender === "Male" && !isMaleCat) return false;
    if (it.gender === "Female" && isMaleCat) return false;
    return true;
  };

  _filteredModels = () => {
    const { modelTab, isMaleCat, models }: any = this.state;
    const _models: any = [];
    for (let i = 0; i < models?.length; i++)
      if (models[i].category === modelTab.beTl) {
        if (models[i].gender === "Male" && isMaleCat) _models.push(models[i]);
        if (models[i].gender === "Female" && !isMaleCat)
          _models.push(models[i]);
      }
    return _models;
  };

  _swapModel = (id1: any, id2: any) => {
    const { models }: any = this.state;
    var pos1 = null;
    var pos2 = null;
    for (let i = 0; i < models.length; i++) {
      if (models?.[i].id === id1) pos1 = i;
      if (models?.[i].id === id2) pos2 = i;
      if (pos1 !== null && pos2 !== null) break;
    }
    if (pos1 !== null && pos2 !== null) {
      var item = models[pos1];
      models.splice(pos1, 1);
      models.splice(pos2, 0, item);
      this.setState({ models });
    }
  };

  render() {
    const { page, isSuccesPop, bannerItem, modelTab }: any = this.state;
    const { modelsLoading, isMaleCat, modelsError, reset }: any = this.state;
    return (
      <div className={cs.body}>
        <div className={cs.banner}>
          <div className={cs.bannerBody}>
            <div className={cs.bannerTxt2}>JOIN US NOW</div>
            {/* <div className={cs.bannerTxt1}>TO BECOME OUR PARTNER</div> */}
            {/* <div className={cs.bannerTxt3}>UC AGENCY</div> */}
            <div className={cs.bannerDesc}>
              <div className={cs.titleAnime}>
                <div className={cs.titleBnr}>{bannerItem?.title}</div>
                <div className={cs.descBnr}>{bannerItem?.desc}</div>
              </div>
            </div>
            <div
              className={cs.regBtn}
              onClick={() => {
                this.setState({ page: "register" });
                window.history.replaceState("UC", "UC", "/agency/register");
              }}
            >
              REGISTER AS A MODEL
            </div>
          </div>
        </div>
        <div
          onClick={() => this.setState({ isSuccesPop: false })}
          className={isSuccesPop ? cs.succesPop : cs.succesPop_}
        >
          <div className={cs.succesPopBody}>
            <div className={cs.smileIcon} />
            <div>Congratulations on successfully registering!</div>
            <div>
              Welcome to our community. We're thrilled to have you here, and we
              can't wait to embark on this journey together
            </div>
            <br />
            <div>Please verify your email first before yoy can login.</div>
            <br />
            <br />
            <a href="https://avidhilda.com/login">
              <div className="blackBtn">SIGN IN NOW</div>
            </a>
          </div>
        </div>
        <RegisterForm
          on={page === "register"}
          onSuccesPop={() => this.setState({ isSuccesPop: true })}
          close={() => {
            window.history.replaceState("UC", "UC", "/agency");
            this.setState({ page: "" });
          }}
        />
        {/* //////////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////////// */}
        <div className={cs.modelsGallery}>
          <div className={cs.title}>Our Models</div>
          <div className={cs.modelNavBar}>
            {modelsCat.map((it: any, k) => (
              <div
                key={k}
                className={modelTab.value === it.value ? cs.selected : ""}
                onClick={() => {
                  this.setState({
                    modelTab: it,
                    isMaleCat: false,
                    reset: false,
                  });
                  setTimeout(() => this.setState({ reset: true }));
                }}
              >
                {it.title}
              </div>
            ))}
          </div>
          <div className={cs.catTitle}>
            <div />
            {modelTab.title}
            <div />
          </div>
          <div className={cs.modelNavBar1}>
            <div
              className={!isMaleCat ? cs.selected : ""}
              onClick={() => {
                this.setState({ isMaleCat: false, reset: false });
                setTimeout(() => this.setState({ reset: true }));
              }}
            >
              Women
            </div>
            <div
              className={isMaleCat ? cs.selected : ""}
              onClick={() => {
                this.setState({ isMaleCat: true, reset: false });
                setTimeout(() => this.setState({ reset: true }));
              }}
            >
              Men
            </div>
          </div>
          {modelsLoading && <div className={cs.warning}>Loading...</div>}
          {modelsError && <div className={cs.warning}>Error on loading.</div>}
          {this._filteredModels().length === 0 && !modelsLoading ? (
            <div className={cs.warning}>
              We do not have models yet in this category.
            </div>
          ) : (
            ""
          )}
          <div className={cs.modelsGalleryBody}>
            {reset &&
              this._filteredModels()?.map((it: any, k: any) => (
                <EachModelProfile
                  key={k + isMaleCat + modelTab.value}
                  it={it}
                  swap={this._swapModel}
                />
              ))}
          </div>
        </div>
        {/* //////////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////////// */}
        {/* //////////////////////////////////////////////////////////////////////////// */}
        <AboutContainer />
        <EventsList events={this.props.events} />
        <ScreenFooter setPage={this.props.setPage} />
        <ScreenHeader setPage={this.props.setPage} />
      </div>
    );
  }
}

var dragging = "";
function EachModelProfile({ it, swap }: any) {
  return (
    <div
      draggable
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        swap(dragging, it.id);
        // console.log(dragging);
        // console.log(it.id);
      }}
      onDragStart={() => (dragging = it.id)}
      className={cs.eachModel}
      onClick={() => (window.location.href = "model?id=" + it?.id)}
    >
      <img className={cs.eachModelImage} src={it.profile_pic} />
      <div className={cs.detailsDiv}>
        <div>
          <b>{it?.name?.split(",")?.[0]}</b>
        </div>
        {it?.height && (
          <div>
            {it?.height} cm
            <br />
            height
          </div>
        )}
        {it?.weight && (
          <div>
            {it?.weight} cm
            <br />
            chest
          </div>
        )}
        {it?.waist && (
          <div>
            {it?.waist} cm
            <br />
            waist
          </div>
        )}
        {it?.hips && (
          <div>
            {it?.hips} cm
            <br />
            hips
          </div>
        )}
        {it?.shoesize && (
          <div>
            {it?.shoesize} cm
            <br />
            shoe size
          </div>
        )}
      </div>
    </div>
  );
}
