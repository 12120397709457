import cs from "./index.module.css";
import { StrictMode, useState } from "react";

const imgList = [
  "https://public.ucfashionweek.com/uc/gallery/press_meet/1.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/2.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/3.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/4.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/5.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/6.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/7.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/8.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/9.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/10.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/11.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/12.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/13.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/14.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/15.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/16.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/17.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/18.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/19.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/20.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/21.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/22.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/23.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/24.jpeg",
  "https://public.ucfashionweek.com/uc/gallery/press_meet/25.jpeg",

  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_13.jpg?alt=media&token=d10aa8d8-d4e6-43bd-9648-9eb787bb83bf",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_12.jpg?alt=media&token=96f2ef5b-4a9c-4af6-b5d0-3c14f192a657",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_11.jpg?alt=media&token=c40bb20b-4965-4998-bdda-b03a0fa19abc",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_10.jpg?alt=media&token=9f371a7e-346a-40ab-aab3-01883566bc04",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_9.jpg?alt=media&token=e9917b8d-2363-417c-acc6-434930c53cfe",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_8.jpg?alt=media&token=22c34a52-00be-4505-b4f0-fe392ab149a8",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_7.jpg?alt=media&token=4dd5c2aa-c577-493c-be18-cd0a233b4442",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_6.jpg?alt=media&token=a8b36083-1a17-40b3-854c-f96d5bbcdc71",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_5.jpg?alt=media&token=3de7f2d3-7537-41e5-9828-798556b2c1e2",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_4.jpg?alt=media&token=4991091d-3270-405c-811d-871552387c0b",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_3.jpg?alt=media&token=2c8148f8-500b-4fa8-91e6-27292b850425",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_2.jpg?alt=media&token=0a80d09e-317b-4d1f-84fa-49043bb29eb7",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Fmou_1.jpg?alt=media&token=82bfc1ea-8fb8-4627-ae96-8a34adb0c039",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_23.jpeg?alt=media&token=a18d370b-977c-4181-8482-6a031d7d10ce",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_22.jpeg?alt=media&token=b81e493d-8cf2-482e-a445-c0074feb621d",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_21.jpeg?alt=media&token=20034138-eaa4-4f37-bdcf-78c42222b89b",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_20.jpeg?alt=media&token=1d01d570-dab7-4607-b0f1-de5e364a2e51",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_19.jpeg?alt=media&token=b5856d26-bb36-466c-8b95-c24f857745dc",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_18.jpeg?alt=media&token=e4217fae-bfb4-43db-9c20-08e2ce9e2169",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_17.jpeg?alt=media&token=9a61742d-13ea-45e7-81f9-2c672cf9e9b4",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_16.jpeg?alt=media&token=f0b8f22b-8985-458d-be37-71a49937cb14",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_15.jpeg?alt=media&token=0f4510af-40e7-4629-9f03-8d468e91f3e7",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_14.jpeg?alt=media&token=0fa1da9e-41e4-43af-a36d-3779c9fbc350",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_13.jpeg?alt=media&token=e8af7799-ed4a-4be6-bb49-21bfc41a6fc1",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_12.jpeg?alt=media&token=ec8f186d-3dc6-4d92-8fbe-bf30123aade1",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_11.jpeg?alt=media&token=a79bf4a1-e3f2-4273-9c9f-59a3d7c5da0a",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_10.jpeg?alt=media&token=9dfb7b0e-1e42-4ea4-be0a-04a93f768434",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_9.jpeg?alt=media&token=ee59c467-18ce-4179-b77d-db75b584e61d",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_8.jpeg?alt=media&token=4fe56ab3-12c3-47c1-ad0c-4c5daf16ed81",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_7.jpeg?alt=media&token=c20fa6d3-3598-4f47-afb2-8801d2155a73",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_6.jpeg?alt=media&token=c193d64e-66bd-47a2-92f4-2146c0da33c5",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_5.jpeg?alt=media&token=a15f7124-c34c-402c-9d92-8a6105652d15",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_4.jpeg?alt=media&token=6b29cfac-d089-4293-b3ee-2f6d3415bfa7",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_3.jpeg?alt=media&token=aa039978-deee-4c96-a324-e62d9f334142",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_2.jpeg?alt=media&token=ef757dc5-ef21-47ff-9a07-771565bc6f10",
  // "https://firebasestorage.googleapis.com/v0/b/fir-auth-a5a9d.appspot.com/o/Highlightimages%2Ftafkeer_1.jpeg?alt=media&token=3eab0e30-1c51-4fd3-9c2a-fc1ca28a8a5d",
];

export default function MediaGallery({ imageList }: any) {
  const [selected, setselected] = useState("");
  const [count, setCount] = useState(7);
  const list: [] = imageList || imgList || [];
  return (
    <StrictMode>
      <div className={cs.section4}>
        {list.slice(0, count).map((it: any, k: number) => (
          <img
            key={k}
            className={cs.eachImage}
            src={it}
            alt="MediaPic"
            onClick={() => setselected(it)}
          />
        ))}
        {list.length > count && (
          <div
            className={cs.loadMore}
            onClick={() => {
              setCount(count + 8);
            }}
          >
            Load More
          </div>
        )}
      </div>
      {selected !== "" && (
        <div className={cs.popup1} onClick={() => setselected("")}>
          <img className={cs.popupBody} src={selected} alt="MediaPic" />
        </div>
      )}
    </StrictMode>
  );
}
