import { Component, StrictMode } from "react";
import cs from "../styles/careers.module.css";
import AboutContainer from "../components/about";
import ScreenHeader, { ScreenFooter } from "../components/foot&head";
import EventsList, { SingleEvent } from "../components/eventsList";
import MediaGallery from "../components/mediaGallery";
import axios from "axios";
import { JobForm } from "../components/registerForm";

const jobs = [
  {
    code: "AVI-XNMG635622",
    title: "Cras sed accumsan dui, sit amet ornare turpis",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sed accumsan dui, sit amet ornare turpis. Nulla rutrum mauris lobortis felis ultricies tincidunt. Aenean nulla risus, mollis quis lorem sit amet, tempus feugiat sem. Nam in lorem ut nulla cursus feugiat at nec sem. Nullam vulputate nisi urna, et interdum sem egestas ut. Suspendisse potenti. Ut quis urna eu mi varius euismod. Morbi velit mauris, ultricies placerat neque vitae, volutpat fermentum sapien. Pellentesque vulputate venenatis porta. Praesent ac purus in mi commodo pharetra.",
    qualification:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sed accumsan dui, sit amet ornare turpis. Nulla rutrum mauris lobortis felis ultricies tincidunt. Aenean nulla risus, mollis quis lorem sit amet, tempus feugiat sem. Nam in lorem ut nulla cursus feugiat at nec sem. Nullam vulputate nisi urna, et interdum sem egestas ut. Suspendisse potenti. Ut quis urna eu mi varius euismod. Morbi velit mauris, ultricies placerat neque vitae, volutpat fermentum sapien. Pellentesque vulputate venenatis porta. Praesent ac purus in mi commodo pharetra.",
    benefits:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sed accumsan dui, sit amet ornare turpis. Nulla rutrum mauris lobortis felis ultricies tincidunt. Aenean nulla risus, mollis quis lorem sit amet, tempus feugiat sem. Nam in lorem ut nulla cursus feugiat at nec sem. Nullam vulputate nisi urna, et interdum sem egestas ut. Suspendisse potenti. Ut quis urna eu mi varius euismod. Morbi velit mauris, ultricies placerat neque vitae, volutpat fermentum sapien. Pellentesque vulputate venenatis porta. Praesent ac purus in mi commodo pharetra.",
    salary: "2000",
  },
];

export default class CareersScreen extends Component<any> {
  constructor(props: any) {
    super(props);
    this.state = {
      jobs: { loading: false, data: [] },
      isApplyForm: false,
      selectedCategory: -1,
      selectedJob: -1,
    };
  }
  async componentDidMount() {
    await axios
      .get("https://api.avidhilda.com/api/career/getCategory/test")
      .then(async (res) => {
        const jobs: any = [];
        for (let i = 0; i < res.data.data.length; i++) {
          await axios
            .get(
              "https://api.avidhilda.com/api/career/getJobs/" +
                res.data.data[i].id
            )
            .then((res1) => jobs.push(res1.data.data))
            .catch(() => {});
        }
        this.setState({ jobs: { data: jobs } });
      })
      .catch(() => {});
  }

  _breakLine = (s: any) => {
    return (
      <StrictMode>
        {s?.split("\r").map((it: any) => (
          <div>{it}</div>
        ))}
      </StrictMode>
    );
  };

  _onClickCat = (k: number) => {
    const { selectedCategory }: any = this.state;
    if (k === selectedCategory)
      this.setState({ selectedCategory: -1, selectedJob: -1 });
    else this.setState({ selectedCategory: k, selectedJob: -1 });
  };
  _onClickJob = (k: number) => {
    const { selectedJob }: any = this.state;
    if (k === selectedJob) this.setState({ selectedJob: -1 });
    else this.setState({ selectedJob: k });
  };

  render() {
    const { jobs, isApplyForm, selectedCategory, selectedJob }: any =
      this.state;
    return (
      <div className={cs.screenBody}>
        <div className={cs.banner}>
          <div className={cs.bannerTitle}>JOIN OUR TEAM</div>
          <div className={cs.bannerDesc}>
            Growth and Development: It may also suggest that the organization
            offers opportunities for professional and personal growth, as
            joining a team often involves learning and skill development.
          </div>
        </div>
        {false ? (
          <div className={cs.jobCards}>
            <div className={cs.a}>
              <div className={cs.a_}>Our Job Vaccancy</div>
              {jobs.data.map((it: any, k: any) => (
                <div
                  className={selectedCategory === k ? cs.aA_ : cs.aA}
                  key={k}
                >
                  <div className={cs.aAa} onClick={() => this._onClickCat(k)}>
                    {it?.category?.title}
                    <div>{selectedCategory === k ? "-" : "+"}</div>
                  </div>
                  <div className={cs.aAb}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this._breakLine(it?.category?.description)}
                  </div>
                  {it?.jobs.map((it1: any, k1: any) => (
                    <div
                      key={k1}
                      className={selectedJob === k1 ? cs.aAc_ : cs.aAc}
                      onClick={() => this._onClickJob(k1)}
                    >
                      <div className={cs.aScA}>
                        {it1?.title}
                        <div>{selectedJob === k1 ? "-" : "+"}</div>
                      </div>
                      <div className={cs.aScB}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {this._breakLine(it1?.description)}
                      </div>
                      <div className={cs.aScC}>
                        <b>Benefits : </b>
                        {it1?.benefits}
                      </div>
                      <div className={cs.aScD}>
                        <b>Job Code : </b>
                        {it1?.code}
                      </div>
                      <div className={cs.aScE}>
                        <b>Qualification : </b>
                        {it1?.qualification}
                      </div>
                      <div className={cs.aScF}>
                        <b>Salary : </b>
                        {it1?.salary}
                      </div>
                      <div className={cs.aScG}>
                        <button
                          className="blackBtn"
                          onClick={() => this.setState({ isApplyForm: true })}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                maxWidth: 1200,
                padding: "80px 20px",
                lineHeight: "25px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={require("../assets/bg/404.avif")}
                alt="404img"
                width="30%"
              />
              <br />
              <div>
                <b>Thank you for your interest in UC!</b>
              </div>
              At UC, we're always excited to connect with talented individuals
              who share our passion for a modeling company. While we don't have
              any specific job openings at the moment, we believe that
              opportunities can arise at any time, and we're constantly on the
              lookout for exceptional talent.
            </div>
          </div>
        )}
        {/* <div className={cs.events}>
          <div className={cs.eventsArea}>
            {this.props.events.loading && "Loading..."}
            {this.props.events.data.map((it: any, k: any) => (
              <SingleEvent it={it} key={k} />
            ))}
          </div>
        </div> */}
        <AboutContainer />
        <MediaGallery />
        <EventsList events={this.props.events} />
        <ScreenFooter setPage={this.props.setPage} />
        <ScreenHeader setPage={this.props.setPage} />
        <JobForm
          on={isApplyForm}
          close={() => this.setState({ isApplyForm: false })}
        />
      </div>
    );
  }
}
